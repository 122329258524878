<aside class="sidebar">
    <div class="sidebar-inner-wrapper">
        <div class="sidebar-menu">
            <ul class="sidebar-menu-list">
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('dashboardMenu')">
                    <a [routerLink]="[URLConstants.DASHBOARD]" class="menu-link"
                        [ngClass]="{'active': router.url.includes(URLConstants.DASHBOARD)}">
                        <em class="bx bx-bar-chart-square"></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>

                <li class="menu-list-item" (click)="openCloseSubMenu('manageSubAdminMenu')">
                    <a [routerLink]="[URLConstants.TEAM_MEMBERS_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.TEAM_MEMBERS_LIST)}">
                        <img style="max-width: 23px;" [hidden]="!router.url.includes(URLConstants.TEAM_MEMBERS_LIST)"
                            src="\assets\images\subadmin_inactive.png" alt="No image">
                        <img style="max-width: 23px;" [hidden]="router.url.includes(URLConstants.TEAM_MEMBERS_LIST)"
                            src="\assets\images\subadmin_active.png" alt="No image">
                        <span class="menu-title">Manage Subadmins</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('manageOperatorsMenu')">
                    <a [routerLink]="[URLConstants.OPERATORS]" class="menu-link"
                        [ngClass]="{'active':(router.url.includes(URLConstants.OPERATORS) || router.url.includes(URLConstants.OPERATOR_DETAIL))}">
                        <img style="max-width: 23px;"
                            [hidden]="!(router.url.includes(URLConstants.OPERATORS) || router.url.includes(URLConstants.OPERATOR_DETAIL))"
                            src="\assets\images\operator_inactive.png" alt="No image">
                        <img style="max-width: 23px;"
                            [hidden]="(router.url.includes(URLConstants.OPERATORS) || router.url.includes(URLConstants.OPERATOR_DETAIL))"
                            src="\assets\images\operator_active.png" alt="No image">
                        <span class="menu-title">Manage Operators</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('subUserMenu')"
                    [hidden]="!accessPermissions?.users?.view">
                    <a [routerLink]="[URLConstants.USERS]" class="menu-link"
                        [ngClass]="{'active':(router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL))}">
                        <img style="max-width: 23px;"
                            [hidden]="!(router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL))"
                            src="\assets\images\user_inactive.png" alt="No image">
                        <img style="max-width: 23px;"
                            [hidden]="(router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL))"
                            src="\assets\images\user_active.png" alt="No image">
                        <span class="menu-title">Manage Users</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subAnalyticsMenu}" [hidden]="!accessPermissions.analytics.view">
                    <a class="menu-link" (click)="openCloseSubMenu('subAnalyticsMenu')"
                        [ngClass]="{'active':subAnalyticsMenu || router.url.includes(URLConstants.APP_USAGE) || router.url.includes(URLConstants.LOGIN_STATISTICS)}">
                        <em class="bx bx-bar-chart-alt"></em>
                        <span class="menu-title">Analytics</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : activeMenuItem === 'subAnalyticsMenu' && !menuClose}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.APP_USAGE]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.APP_USAGE)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">App usage</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.LOGIN_STATISTICS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.LOGIN_STATISTICS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Login Statistics</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <!-- <li class="menu-list-item has-child-submenu level1">
                    <a class="menu-link" (click)="openCloseSubMenu('subUserMenu')"
                        [ngClass]="{'active': router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL) || router.url.includes(URLConstants.CONTENT_PROVIDERS) || router.url.includes(URLConstants.CONTENT_PROVIDERS_DETAIL)}">
                        <em class="bx bx-group"></em>
                        <span class="menu-title">Manage Users</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : activeMenuItem === 'subUserMenu' && !menuClose}">
                        <li class="menu-list-item" [hidden]="!accessPermissions?.users?.view">
                            <a [routerLink]="[URLConstants.USERS]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.CONTENT_PROVIDERS]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.CONTENT_PROVIDERS) || router.url.includes(URLConstants.CONTENT_PROVIDERS_DETAIL))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Content Providers</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-list-item" (click)="openCloseSubMenu('subCoursesMenu')"
                    [hidden]="!accessPermissions?.courses?.view">
                    <a [routerLink]="[URLConstants.COURSES_LIST]" class="menu-link"
                        [ngClass]="{'active': router.url.includes(URLConstants.COURSES_LIST)}">
                        <em class="bx bx-news"></em>
                        <span class="menu-title">Moderate Training Courses</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('trainingCourseRequestMenu')">
                    <a [routerLink]="[URLConstants.COURSE_REQUESTS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSE_REQUESTS)}">
                        <em class="bx bx-git-compare"></em>
                        <span class="menu-title">Training Course Requests</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('productsMenu')"
                    [hidden]="!accessPermissions?.products?.view">
                    <a [routerLink]="[URLConstants.PRODUCTS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PRODUCTS)}">
                        <em class="bx bx-cube-alt"></em>
                        <span class="menu-title">Moderate Products</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('ResoursesMenu')"
                    [hidden]="!accessPermissions?.resources?.view">
                    <a [routerLink]="[URLConstants.RESOURCES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.RESOURCES)}">
                        <em class="bx bx-spreadsheet"></em>
                        <span class="menu-title">Moderate Resources</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('NewsMenu')"
                    [hidden]="!accessPermissions?.news?.view">
                    <a [routerLink]="[URLConstants.NEWS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.NEWS)}">
                        <em class="bx bx-receipt"></em>
                        <span class="menu-title">Moderate News</span>
                    </a>
                </li>
                <!-- 
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.JOBS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.JOBS)}">
                        <em class="bx bx-briefcase-alt"></em>
                        <span class="menu-title">Jobs</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subDirectoryMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subDirectoryMenu')"
                        [ngClass]="{'active':subDirectoryMenu || router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-data"></em>
                        <span class="menu-title">Directories</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show': activeMenuItem === 'subDirectoryMenu' && !menuClose}">
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Product Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Resource Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Course Directory</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-happy-alt"></em>
                        <span class="menu-title">Feedback</span>
                    </a>
                </li>
                <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subReportsMenu}"
                    [hidden]="!accessPermissions.reports.view">
                    <a class="menu-link" (click)="openCloseSubMenu('subReportsMenu')"
                        [ngClass]="{'active':subReportsMenu || router.url.includes(URLConstants.NEVER_LOGGED_IN)}">
                        <em class="bx bx-chart"></em>
                        <span class="menu-title">Reports</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : activeMenuItem === 'subReportsMenu' && !menuClose}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.NEVER_LOGGED_IN]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.NEVER_LOGGED_IN)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Never Logged In</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-list-item has-child-submenu level1">
                    <a class="menu-link" (click)="openCloseSubMenu('subMasterDataMenu')"
                        [ngClass]="{'active': router.url.includes(URLConstants.INDUSTRY_LIST) || router.url.includes(URLConstants.ADD_INDUSTRY) || router.url.includes(URLConstants.EDIT_INDUSTRY) || router.url.includes(URLConstants.PRODUCT_TYPES_LIST)  || router.url.includes(URLConstants.ADD_PRODUCT_TYPE) || router.url.includes(URLConstants.EDIT_PRODUCT_TYPE) || router.url.includes(URLConstants.CANCELLATION_REASON_LIST) || router.url.includes(URLConstants.ADD_CANCELLATION_REASON) || router.url.includes(URLConstants.EDIT_CANCELLATION_REASON)}">
                        <em class="bx bx-group"></em>
                        <span class="menu-title">Master Data</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list"
                        [ngClass]="{'show' : activeMenuItem === 'subMasterDataMenu' && !menuClose}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.INDUSTRY_LIST]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.INDUSTRY_LIST) || router.url.includes(URLConstants.EDIT_INDUSTRY) || router.url.includes(URLConstants.ADD_INDUSTRY) )}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Industry</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!accessPermissions?.product_type?.view">
                            <a [routerLink]="[URLConstants.PRODUCT_TYPES_LIST]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.PRODUCT_TYPES_LIST) || router.url.includes(URLConstants.ADD_PRODUCT_TYPE) || router.url.includes(URLConstants.EDIT_PRODUCT_TYPE))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Product Types</span>
                            </a>
                        </li>
                        <!-- <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.CANCELLATION_REASON_LIST]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.CANCELLATION_REASON_LIST) || router.url.includes(URLConstants.ADD_CANCELLATION_REASON) || router.url.includes(URLConstants.EDIT_CANCELLATION_REASON))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Cancellation Reason</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('emailMenu')">
                    <a [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.EMAIL_TEMPLATE_list)}">
                        <em class='bx bx-envelope'></em>
                        <span class="menu-title">Manage Email Templates</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('notificationMenu')">
                    <a [routerLink]="[URLConstants.PUSH_NOTIFICATION_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PUSH_NOTIFICATION_LIST)}">
                        <em class='bx bx-bell'></em>
                        <span class="menu-title">Manage Push Notification Templates</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('myAccountMenu')">
                    <a [routerLink]="[URLConstants.MY_ACCOUNT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MY_ACCOUNT)}">
                        <em class="bx bx-cog"></em>
                        <span class="menu-title">My Account</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="upgrade-course">
                <div class="arrow-up"><em class="bx bx-up-arrow-alt"></em></div>
                <h5>Upgrade to Pro for more resources</h5>
                <a href="javascript:;" class="upgrade-btn">Upgrade</a>
            </div> -->
        </div>
    </div>
</aside>
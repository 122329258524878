import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
/* pipe */
import { DateFilterPipe, LimitTextPipe, NegToPosPipe } from '../common/service/common-pipe.pipe';
/* plugin */
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { NgxSpinnerModule } from "ngx-spinner";
import { QRCodeModule } from 'angularx-qrcode';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LineTruncationLibModule, LineTruncationDirective } from 'ngx-line-truncation';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RatingModule } from 'ngx-bootstrap/rating';
import { HighchartsChartModule } from 'highcharts-angular';
/* Components */
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';

@NgModule({
  imports: [
    AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    CountdownModule,
    BnDatatableModule,
    DataTablesModule,
    NgxSpinnerModule,
    QRCodeModule,
    NgxFileDropModule,
    LineTruncationLibModule,
    SlickCarouselModule,
    HighchartsChartModule,
    TabsModule.forRoot(),
    RatingModule.forRoot(),
    //OwlDateTimeModule,
    //OwlNativeDateTimeModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    SubHeaderComponent,
    /* pipe */
    LimitTextPipe,
    DateFilterPipe,
    NegToPosPipe,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    /* components */
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    DragDropModule,
    SubHeaderComponent,
    /* modules */
    FormsModule,
    CommonModule,
    BnDatatableModule,
    /* pipe */
    LimitTextPipe,
    DateFilterPipe,
    NegToPosPipe,
    /* plugin */
    AngularEditorModule,
    ImageCropperModule,
    AccordionModule,
    BsDatepickerModule,
    TimepickerModule,
    TabsModule,
    QRCodeModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    CountdownModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    ProgressbarModule,
    LineTruncationDirective,
    SlickCarouselModule,
    RatingModule,
    HighchartsChartModule,
  ]
})
export class SharedModule { }

export const environment = {
  production: false,
  environmentName: 'dev',
  port: 4050,
  recaptcha: {
    siteKey: '6LeTY2AjAAAAAOBeDlqoy5G7V3zM9zAuBHt4u8VP',
    secrectKey: '6LeTY2AjAAAAAFj70B3cotCeJdSzrohK5mz0VEdp',
  },
  localStorageEncryptionSecret: '!@#$&skillsbase&@dev!',
  apiUrl: 'https://vnops-api.devpress.net/v1/admin/',
};
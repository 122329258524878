import { Component } from '@angular/core';
import { Router } from '@angular/router';
/************ plugin  ************/
import { RecaptchaErrorParameters } from 'ng-recaptcha';
/************ environment  ************/
import { environment } from 'src/environments/environment';
/* constants */
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';
/* services */
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';
import { LocalStorageService } from '../../common/service/local-storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: []
})
export class ForgotPasswordComponent {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  siteKey = environment.recaptcha.siteKey;
  public user: any = {};
  public submitted: boolean = false;

  constructor(private router: Router, private commonService: CommonService, private localStorageService: LocalStorageService, public showErrorService: ShowErrorService) { }

  /*************************************************************
  @PURPOSE      : Forget Password
  @PARAMETERS   : form, user
  @RETURN       : NA
  /*************************************************************/
  onForgotPassword(form, user) {
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.ForgotPassword, user, 'post', true, false).then((success) => {
        if (!success.errors) {
          window.sessionStorage.setItem('email', this.commonService.encrypt(user.email));
          this.showErrorService.popToast('success', success.extra_meta.message);
          this.router.navigate([URLConstants.VERIFY_CODE])
        } else {
          this.showErrorService.popToast('error', 'Please enter registered email');
        }
      })
    }
  }

  /*************************************************************
 @PURPOSE      : callbacks for Recaptcha execution
 /*************************************************************/
  resolved(captchaResponse: string, form, user): void {
    if (captchaResponse) {
      user.captcha_response = captchaResponse;
      this.onForgotPassword(form, user);
    }
  }
  onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  /*************************************************************/

}

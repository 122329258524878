<div class="page-wrap">
    <!-- START : Header -->
    <app-header></app-header>

    <!-- END : Header -->
    <div class="admin-wrapper">

        <!-- START : Sidebar -->
        <app-sidebar id="sidebar" class="sidebar-close"></app-sidebar>
        <!-- END : Sidebar -->

        <div class="admin-content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
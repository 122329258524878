import { Component, OnInit, ViewChild } from '@angular/core';
/* interface */
import { IBreadcrumbs, ITableSetupData } from '../../common/interfaces/interfaces';
/* constants */
import { URLConstants } from '../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../common/constants/callAPI-constants';
/* services */
import { CommonService } from '../../common/service/common.service';
import { RolePermissionService } from '../../common/service/role-permission.service';

@Component({
  selector: 'app-training-courses',
  templateUrl: './training-courses.component.html',
  styles: [
  ]
})
export class TrainingCoursesComponent implements OnInit {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;

  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  public accessPermissions: any = {};
  title = 'Moderate Training Courses';

  constructor(private commonService: CommonService, private rolePermissionService: RolePermissionService) {
    this.setBreadcrumbs();
    /* START: Role Permission */
    this.accessPermissions = this.rolePermissionService.getPagePermission('courses');
    /* END: Role Permission */
  }

  ngOnInit() {
    this.setDTableInitialData();
  }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: this.title, url: null },
    ];
    this.subHeaderData = {
      breadcrumbs: this.breadcrumbs,
    };
  }

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true, columnVisibility: false },
      { type: 'text', colName: 'Course Name', colFieldname: 'title', isPic: 'primary_image', isVisible: true, sort: true, filter: true, columnVisibility: true },
      { type: 'text', colName: 'Operator', colFieldname: 'operator', isVisible: true, sort: true, filter: true, columnVisibility: true },
      { type: 'text', colName: 'Created By', colFieldname: 'created_by', isVisible: true, sort: true, filter: true, columnVisibility: true },
      { type: 'text', colName: 'Created At', colFieldname: 'created_at', isDateTime: true, isVisible: true, sort: true, filter: true, columnVisibility: true },
      { type: 'text', colName: 'Price', colFieldname: 'price', isVisible: true, sort: true, filter: true, columnVisibility: true },
      { type: 'switch', colName: 'Status', colFieldname: 'status', isVisible: true, sort: false, filter: true, columnVisibility: true, permission: this.accessPermissions.edit },
    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'courseList';
    this.tableSetupData.actions = [
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit-alt',
        type: 'icon', permission: this.accessPermissions.edit
      },
      {
        id: 3, buttonTitle: 'Delete', tooltip: 'Delete', class: 'bx bx-trash',
        type: 'icon', permission: this.accessPermissions.delete
      },
    ];
    this.tableSetupData.params = { deleteParams: 'ids', statusParams: 'ids' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showColumnHide: true,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,
      search: true,
    };
  }
  /*************************************************************/

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/* Constant */
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';
/* Service */
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { CommonService } from 'src/app/common/service/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  localStorageConstants = localStorageConstants;

  public accessPermissions: any = {};
  activeMenuItem = '';

  constructor(public router: Router, public localStorageService: LocalStorageService, public commonService: CommonService) {
  }

  ngOnInit(): void {
    /* START: Role Permission */
    if (this.localStorageService.getToken(this.localStorageConstants.Permissions) !== 'undefined') {
      this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
        if (!success.errors) {
          this.accessPermissions = success.data;
        }
      });
    }    /* END: Role Permission */
  }

  /*********************************************************************************
   @PURPOSE      : Open/ Close Menus
   @PARAMETERS   : menuName
   @RETURN       : N/A
   /*********************************************************************************/
  menuClose = false;
  openCloseSubMenu(menuName) {
    if (this.activeMenuItem == menuName) {
      this.menuClose = !this.menuClose;
    }
    this.activeMenuItem = menuName;
  }

}

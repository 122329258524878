import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/* Constants */
import { callAPIConstants } from '../constants/callAPI-constants';
import { localStorageConstants } from '../constants/localStorage-constants';
/* Services */
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {
  callAPIConstants = callAPIConstants;
  localStorageConstants = localStorageConstants
  public accessPermissions: BehaviorSubject<any>
  constructor(private commonService: CommonService, private localStorageService: LocalStorageService,public logoutService:LogoutService) {
    if(  this.localStorageService.getToken(this.localStorageConstants.Permissions) !=='undefined'){
      this.accessPermissions = new BehaviorSubject<any>(this.localStorageService.getToken(this.localStorageConstants.Permissions));
    } else {
      this.logoutService.logout();
      this.localStorageService.removeToken(this.localStorageConstants.Permissions);
      this.commonService.router.navigate(['login']);
    }  
  }

  /***************************************************************************
  @PURPOSE      : Get Permission
  @PARAMETERS   : N/A
  @RETURN       : Permission Observable
  ****************************************************************************/
  getPermissionData(): Observable<any> {
    return this.accessPermissions.asObservable();
  }
  /***************************************************************************
  @PURPOSE      : Set Permission
  @PARAMETERS   : details
  @RETURN       : N/A
  ****************************************************************************/
  setPermissionData(details) {
    this.localStorageService.setToken(this.localStorageConstants.Permissions, details);
    this.accessPermissions = new BehaviorSubject<any>((details));
  }

  /***************************************************************************
   @PURPOSE      : Get Permissions List of Logged in User
   @PARAMETERS   : N/A
   @RETURN       : N/A
   ****************************************************************************/
  getPermissions() {
     this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
       if (!success.errors) {
        this.setPermissionData(success.data);
       }
     });
  }
  /***************************************************************************
  @PURPOSE      : Get Page wise Permissions
  @PARAMETERS   : page
  @RETURN       : access object
  ****************************************************************************/
  getPagePermission(page): Object {
    let pageRes: any = {}
    this.getPermissionData().subscribe(res => pageRes = res);

    switch (page) {
      case 'dashboard':
        return pageRes.dashboard;
      case 'courses':
        return pageRes.courses;
      case 'users':
        return pageRes.users;
      case 'user_groups':
        pageRes.user_groups.user_view = pageRes.users.view;
        return pageRes.user_groups;
      case 'products':
        return pageRes.products;
        case 'product_type':
        return pageRes.product_type;
      case 'resources':
        return pageRes.resources;
      case 'news':
        return pageRes.news;
      case 'reports':
        return pageRes.reports;
      case 'analytics':
        return pageRes.analytics;
      case 'feedback':
        return pageRes.feedback;
      case 'news_library':
        return pageRes.news_library;
      case 'product_directory':
        return pageRes.product_directory;
      case 'training_course_directory':
        return pageRes.training_course_directory;
    }
  }


  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : N/A
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    tempData[index].isVisible = false;
    return tempData;
  }
}

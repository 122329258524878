import { Component, Input } from '@angular/core';
/* constants */
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styles: [
  ]
})

export class SubHeaderComponent {
  @Input() subHeaderData: any;
  URLConstants = URLConstants;

}

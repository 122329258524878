import { environment } from "src/environments/environment";

export const localStorageConstants = {
    AccessToken: 'accessToken',
    Token: 'token',
    Pass: 'Pass',
    Id: 'Id',
    Permissions: 'permissions',
    FirstName: 'firstname',
    UserId: 'userId',
    Name: 'name',
    Photo: 'photo',
    Email: 'email'
}

export const SECURE_LS = {
    ENCODING_TYPE: 'aes',
    STORAGE_KEY: '__secure__sb__ls__',
    ENCRYPTION_SECRET: environment.localStorageEncryptionSecret,
};

import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
/* services */
import { LogoutService } from 'src/app/common/service/logout.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { CommonService } from '../../../common/service/common.service';


/* constants */
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  localStorageConstants = localStorageConstants
  URLConstants = URLConstants;
  public dropdown: boolean = false;
  public adminName: any;
  public photo: any;
  passwordTypeOld: boolean;
  passwordTypeNew: boolean;
  public submitted = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;


  constructor(private localStorageService: LocalStorageService,
    private logoutService: LogoutService, private showErrorService: ShowErrorService,
    private router: Router, private commonService: CommonService,
    private modalService: BsModalService) {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    this.adminName = this.localStorageService.getToken(this.localStorageConstants.Name);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    /* set up the div "id=nav" */
    if (document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0) {
      document.getElementById('header').classList.add('sticky');
    } else {
      document.getElementById('header').classList.remove('sticky');
    }
  }

  toggleFieldTextTypeOld() {
    this.passwordTypeOld = !this.passwordTypeOld;
  }

  toggleFieldTextTypeNew() {
    this.passwordTypeNew = !this.passwordTypeNew;
  }

  logout() {
    this.logoutService.logout();
  }

  openSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input search-open');
    document.getElementsByTagName('body')[0].classList.add('body-search-open');
  }

  closeSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input');
    document.getElementsByTagName('body')[0].classList.remove('body-search-open');
  }

  ngDoCheck(): void {
    this.adminName = this.localStorageService.getToken(this.localStorageConstants.Name);
    this.photo = this.localStorageService.getToken(this.localStorageConstants.Photo);
  }
  /****************************************************************************/
}


export const callAPIConstants = {
    AdminLogin: 'login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'verifyEmailToken',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    AdminLogout: 'logout',
    /* Account APIs */
    GetAccountDetail: 'myAccount',
    UpdateAccountDetails: 'myAccount',
    ChangePassword: 'changePassword',
    /* Permission API */
    GetPermissionList: 'getPermissions',
    /* Dashboard APIs */
    GetDashboardDetails: 'dashboard',
    RecentOperatorList: 'dashboard/recentOperatorList',
    /* Email Template APIs */
    EmailTemplateList: 'emailTemplates/getEmailTemplateList',
    AddEmailTemplate: 'emailTemplates',
    EmailTemplateDetail: 'emailTemplates/',
    UpdateEmailTemplate: 'emailTemplates/',
    EmailColumnData: 'getEmailColumnValues',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',
    /*  Role APIs */
    RolesList: 'roles/getRoleList',
    AddRole: 'roles',
    GetRoleDetail: 'roles/',
    DeleteRole: 'roles/delete',
    RoleChangeStatus: 'roles/changeStatus',
    RoleGetColumnData: 'roles/roleFieldsList',
    RoleGetModuleData: 'roles/roleModuleList',
    RoleGetaddedByColumnData: 'adminListInRoles',
    /* Team Member */
    AddTeamMember: 'teamMembers',
    GetTeamMember: 'teamMembers/',
    TeamMembers: 'teamMembers/getListing',
    TeamChangeStatus: 'teamMembers/changeStatus',
    TeamResendPassword: 'teamMembers/resendPassword/',
    /* Users & Operators & Content Prividers */
    UserList: 'users/getUsersList',
    UserChangeStatus: 'users/changeStatus',
    GetUserDetail: 'users/getUserProfile/',
    UserOperatorList: 'users/getUserOperatorList',
    DeleteUser: 'users/delete',
    GetAllOperators: 'users/getAllOperators',
    AssignOperator: 'users/assignOperators',
    OperatorList: 'operators/getListing',
    GetOperatorById: 'operators/',
    AddOperator: 'operators',
    OperatorChangeStatus: 'operators/changeStatus',
    GetDeletedCourseList: 'operators/deletedCourseList',
    RestoreDeletedCourses: 'operators/restoreCourses',
    ResendPassword: 'resendPassword/',
    GetDomains: 'domainMapping',
    OperatorFeatures: 'features',
    /* Training Course APIs */
    GetCourseList: 'trainingCourse/getListing',
    GetCourseById: 'trainingCourse/',
    FileUpload: 'uploadImage',
    ChangeCourseStatus: 'trainingCourse/changeStatus',
    /* Training Course Requests APIs */
    GetRequestedCourses: 'trainingCourse/getCourseRequests',
    ChangeRequestedCourseStatus: 'trainingCourse/changeRequestStatus',
    DeleteCourseRequest: 'trainingCourse/deleteCourseRequest',
    /* Products APIs */
    GetProductList: 'products/getListing',
    ChangeProductStatus: 'products/changeStatus',
    /* Resources APIs */
    GetResourceList: 'resources/getListing',
    ChangeResourceStatus: 'resources/changeStatus',
    /* News APIs */
    GetNewsList: 'news/getListing',
    GetNewsById: 'news/',
    ChangeNewsStatus: 'news/changeStatus',

    /* Push notification template APIs */
    GetPushNotificationtemplateList: 'pushNotificationTemplate/getPushNotificationtemplateList',
    PushNotificationTemplateDetail: 'pushNotificationTemplate/',
    UpdatePushNotificationTemplate: 'pushNotificationTemplate/',
    /**  Master Data APIs **/
    /* Industry APIs */
    GetIndustriesList: 'industries/getListing',
    ChangeIndustriesStatus: 'industries/changeStatus',
    DeleteIndustry: 'industries/',
    GetIndustryById: 'industries/',
    AddIndustry: 'industries',
    ImportIndustries: 'industries/importIndustries',
    /* Product Types APIs */
    GetProductTypesList: 'productTypes/getListing',
    ChangeProductTypeStatus: 'productTypes/changeStatus',
    DeleteProductType: 'productTypes/',
    GetProductTypeById: 'productTypes/',
    AddProductType: 'productTypes',
    ImportProductTypes: 'productTypes/importProductTypes',
    /* Cancellation Reason APIs (Pending) */
    GetCancellationReasonList: 'cancellation/getListing',
    ChangeCancellationReasonStatus: 'cancellation/changeStatus',
    DeleteCancellationReason: 'cancellation/',
    GetCancellationReasonById: 'cancellation/',
    AddCancellationReason: 'cancellation',
    ImportCancellationReason: 'cancellation/importCancellation',
    verifyRegisteredUser: 'users/verifyRegisteredUser'

};


import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RestrictKeyPressService {

	/****************************************************************************
	@PURPOSE      : To restrict or allow some values in input.
	@PARAMETERS   : $event
	@RETURN       : Boolen
	****************************************************************************/
	RestrictSpace(e) {
		if (e.keyCode == 32) {
			return false;
		} else {
			return true;
		}
	}

	AllowNumbers(e) {
		let input;
		if (e.metaKey ||
			e.ctrlKey) {
			return true;
		}
		if (e.which === 32) {
			return false;
		}
		if (e.which === 0) {
			return true;
		}
		if (e.which < 33) {
			return true;
		}
		if (e.which === 43 ||
			e.which === 45) {
			return true;
		}
		if (e.which === 36 ||
			e.which === 35) {
			return true;
		}
		if (e.which === 37 ||
			e.which === 39) {
			return true;
		}

		input = e.key;
		return !!/[\d\s]/.test(input);
	}

	AllowDigits(e) {
		if ((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8) {
			return true;
		} else {
			return false;
		}
	}

	AllowChar(e) {
		if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode == 8) {
			return true;
		} else {
			return false;
		}
	}

	/*********************************************************************************
@PURPOSE      : To allow the characters without allowing multiple spaces.
/*********************************************************************************/
	public count;
	allowCharacters(event) {
		if (event.keyCode == 32 && !event.target.value.length) {
			return false;
			//    }else  if(event.keyCode  ==  32  &&  this.count  <  1){
			//     this.count++;
			//     return  true;
			//    }else  if(event.keyCode  !=  32){
			//     this.count  =  0;
			//     return  true;
			//    } else  if(this.count  >=  1){
			//     return  false; 
		}
	}
	/****************************************************************************/
	validateEditorData(event, fieldName) {
		if (event.keyCode == 32 && !fieldName) {
			return false;
		} else if (event.keyCode == 32 && this.count < 1) {
			this.count++;
			return true;
		} else if (event.keyCode != 32) {
			this.count = 0;
			return true;
		} else if (this.count >= 1) {
			return false;
		}
	}
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
/* plugins */
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
/* constants */
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
/* services */
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],
})
export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterForm', { static: false }) filterForm;
  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public searchText: any;
  showSkeletonLoader1: boolean = true;

  @Input() columns: Array<any>;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleFilterButton: boolean = false;
  cols: any = [];
  configs: any = {};
  public colSettingCols: Array<any> = [];
  status: boolean = false;
  public isCheckable: boolean = true;
  /* Action */
  public actions: any = [{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }];
  active: boolean = false;
  toggleDropdown() {
    this.active = !this.active;

  }

  /* open filter button */
  openFilter() {
    this.status = !this.status;
    this.tableData.data.forEach(ele => ele.isOpen = false);
    this.columnVisibilityOptions = false;
  }
  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date()

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService,
    private router: Router, public bsModalRef: BsModalRef, private modalService: BsModalService, public restrictKeyPressService: RestrictKeyPressService, public zone: NgZone) {
    this.APIparameters = { page: 1, per_page: 100 };

    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];

  }

  ngOnInit() {
    this.addTask();

    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;

    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.colSettingCols.push(element)
      }

    });
    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    if (this.type !== 'courseRequests' && this.type !== 'deletedCourseList') {
      this.statusList = ['Active', 'Inactive'];
    } else if (this.type == 'deletedCourseList') {
      this.statusList = ['Restore'];
    } else {
      this.statusList = ['Pending', 'In Progress', 'Completed'];
    }
    if (this.conditions.showApplyDelete) {
      this.statusList.push('Delete');
    }
    /*************************************************************/
    this.callApiToGetData();

  }
  addTask() {
    this.filteredArr.push({
      condition: this.condition
    })
  }

  ngAfterViewInit() {
    /* options for datatable */
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    /* Do not forget to unsubscribe the event */
    this.dtTrigger.unsubscribe();
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: string;
  public httpMethodName: string;
  public getFilterList: string;
  public DeleteUser: string;
  public ImportEXCEL: string;
  public ChangeStatus: string;
  public RestoreCourse: string;
  public ResendPassword: string;

  async callApiToGetData() {
    if (this.type) {
      switch (this.type) {
        case 'userList':
          this.getMethodName = this.callAPIConstants.UserList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
          this.ResendPassword = this.callAPIConstants.ResendPassword + 'users/';
          this.DeleteUser = this.callAPIConstants.DeleteUser;
          break;
        case 'userOperatorlist':
          this.getMethodName = this.callAPIConstants.UserOperatorList;
          this.httpMethodName = 'post';
          this.APIparameters.id = this.tableSetupData.innerTableId;
          break;
        case 'operatorList':
          this.getMethodName = this.callAPIConstants.OperatorList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.OperatorChangeStatus;
          this.DeleteUser = this.callAPIConstants.GetOperatorById;
          this.ResendPassword = this.callAPIConstants.ResendPassword + 'operators/';
          break;
        case 'emailTemplateList':
          this.getMethodName = this.callAPIConstants.EmailTemplateList;
          this.httpMethodName = 'post';
          this.getFilterList = this.callAPIConstants.EmailColumnData;
          this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
          this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
          break;
        case 'courseList':
          this.getMethodName = this.callAPIConstants.GetCourseList;
          this.httpMethodName = 'post';
          this.DeleteUser = this.callAPIConstants.GetCourseById;
          this.ChangeStatus = this.callAPIConstants.ChangeCourseStatus;
          break;
        case 'deletedCourseList':
          this.getMethodName = this.callAPIConstants.GetDeletedCourseList;
          this.httpMethodName = 'post';
          this.APIparameters.operator_id = this.tableSetupData.parentId;
          this.RestoreCourse = this.callAPIConstants.RestoreDeletedCourses;
          break;
        case 'roleList':
          this.getMethodName = this.callAPIConstants.RolesList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
          this.getFilterList = this.callAPIConstants.RoleGetColumnData;
          this.DeleteUser = this.callAPIConstants.DeleteRole;
          break;
        case 'teamMemeberList':
          this.getMethodName = this.callAPIConstants.TeamMembers;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.TeamChangeStatus;
          this.DeleteUser = this.callAPIConstants.GetTeamMember;
          this.ResendPassword = this.callAPIConstants.TeamResendPassword;
          break;
        case 'courseRequests':
          this.getMethodName = this.callAPIConstants.GetRequestedCourses;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeRequestedCourseStatus;
          this.DeleteUser = this.callAPIConstants.DeleteCourseRequest;
          break;
        case 'productsList':
          this.getMethodName = this.callAPIConstants.GetProductList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeProductStatus;
          break;
        case 'resourcesList':
          this.getMethodName = this.callAPIConstants.GetResourceList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeResourceStatus;
          break;
        case 'newsList':
          this.getMethodName = this.callAPIConstants.GetNewsList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeNewsStatus;
          break;
        case 'industryList':
          this.getMethodName = this.callAPIConstants.GetIndustriesList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeIndustriesStatus;
          this.DeleteUser = this.callAPIConstants.DeleteIndustry;
          this.ImportEXCEL = this.callAPIConstants.ImportIndustries;
          break;
        case 'productTypesList':
          this.getMethodName = this.callAPIConstants.GetProductTypesList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeProductTypeStatus;
          this.DeleteUser = this.callAPIConstants.DeleteProductType;
          this.ImportEXCEL = this.callAPIConstants.ImportProductTypes;
          break;
        case 'cancellationReasonList':
          this.getMethodName = this.callAPIConstants.GetCancellationReasonList;
          this.httpMethodName = 'post';
          this.ChangeStatus = this.callAPIConstants.ChangeCancellationReasonStatus;
          this.DeleteUser = this.callAPIConstants.DeleteCancellationReason;
          this.ImportEXCEL = this.callAPIConstants.ImportCancellationReason;
          break;
        case 'pushNotificationTemplateList':
          this.httpMethodName = 'post';
          this.getMethodName = this.callAPIConstants.GetPushNotificationtemplateList;
          break;
        case 'recentOperatorList':
          this.httpMethodName = 'post';
          this.getMethodName = this.callAPIConstants.RecentOperatorList;
          break;
      }
    }

    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, this.httpMethodName, false, false, false).then((success) => {
        if (!success.errors) {
          this.showSkeletonLoader1 = false;
          this.tableData.data = success.data;
          if (this.tableData.data && (this.type == "roleList" || this.type == "teamMemeberList" || this.type == 'courseList' || this.type === 'userList' || this.type === 'operatorList' || this.type === 'productsList' || this.type === 'resourcesList' || this.type === 'newsList' || this.type === 'industryList') || this.type === 'productTypesList' || this.type === 'cancellationReasonList') {
            this.tableData.data.forEach(e => {
              if (e.status === 'Inactive') {
                e.status = false;
              } else if (e.status === 'Active') {
                e.status = true;
              }
            });
          }
          if (this.type === 'courseRequests') {
            this.tableData.data.forEach(e => {
              switch (e.status) {
                case 'Pending': e.status = { title: 'PENDING', value: 'Pending' };
                  break;
                case 'Inprogress': e.status = { title: 'IN PROGRESS', value: 'Inprogress' };
                  break;
                case 'Completed': e.status = { title: 'COMPLETED', value: 'Completed' };
                  break;
              }
            });
          }
          if (this.type === 'resourcesList') {
            this.tableData.data.forEach(e => {
              if (e.resource_type === 'vimeo') {
                e.link = 'https://vimeo.com/' + e.resource_id;
              } else if (e.resource_type === 'youtube') {
                e.link = 'https://www.youtube.com/watch?v=' + e.resource_id;
              } else {
                e.link = e.resource_id;
              }
            });
          }
          if (this.tableData.data) {
            this.tableData.data.forEach(item => {
              this.isCheckable = true;
              this.selectedUserList.forEach(e => {
                if (item.id == e) {
                  item.checkbox = true;
                  this.selectMultipleUser(item);
                }
              });
            });
          }
          this.total = success.meta && success.meta.total ? success.meta.total : this.tableData.data.length;
        } else {
          this.commonService.showSkeletonLoader = false;
          this.showSkeletonLoader1 = false;
        }
      });
    }
  }

  arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = "";
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      }
    })
    if (!this.count) {
      delete this.options.templateName;
    }
    // else {
    // }
  }


  importTemplate(event) {
    const file = event.target && event.target.files && event.target.files[0];
    const fd = new FormData();
    fd.append('file', file);
    this.commonService.callApi(this.ImportEXCEL, fd, 'post', false, true, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast("success", success.extra_meta.message);
        this.callApiToGetData();
      } else {
        this.commonService.showSkeletonLoader = false;
        this.showSkeletonLoader1 = false;
        this.showErrorService.popToast("error", success.errors.message);
      }
    });
  }

  exportTemplate() {
    if (this.tableData.data) {
      let templateData: any = { exportFields: [] };
      templateData.isExport = 1;
      this.tableConfig.cols.forEach((element) => {
        if (element.columnVisibility && element.isVisible) {
          templateData.exportFields.push(element.colFieldname);
        }
      });

      if (this.APIparameters.filters) {
        templateData.filters = this.APIparameters.filters;
      }
      if (this.APIparameters.search_key) {
        templateData.search_key = this.APIparameters.search_key;
      }
      if (templateData.exportFields.length > 1) {
        this.commonService.callApi(this.getMethodName, templateData, 'post', false, false).then(success => {
          if (!success.errors) {
            const blob = new Blob([success.text], { type: 'text/csv' });
            let fileName: any;
            switch (this.type) {
              case 'teamMemeberList':
                fileName = 'Teammates-';
                break;
              case 'courseList':
                fileName = 'Training courses-';
                break;
              case 'userList':
                fileName = 'Users-';
                break;
              case 'operatorList':
                fileName = 'Operators-';
                break;
              case 'productsList':
                fileName = 'Products-';
                break;
              case 'resourcesList':
                fileName = 'Ressources-';
                break;
              case 'newsList':
                fileName = 'News-';
                break;
              case 'productTypesList':
                fileName = 'Product Types-';
                break;
              case 'industryList':
                fileName = 'Industry List-';
                break;
              case 'courseRequests':
                fileName = 'course Request-';
                break;
              default:
                fileName = 'download-list-';
                break;
            }
            saveAs(blob, fileName + new Date().getTime() + '.csv');
            this.commonService.showSkeletonLoader = false;
            this.showSkeletonLoader1 = false;
          } else {
            this.commonService.showSkeletonLoader = false;
            this.showSkeletonLoader1 = false;
            this.showErrorService.popToast('error', 'Error downloading the file.');
          }
        });
      }
    } else {
      this.showErrorService.popToast('error', 'No data available for download.');
    }
  }

  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }
  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: 'Action' };
  public statusList: any[];

  selectMultipleUser(data) {

    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data.id);
        if (x === -1) {
          this.selectedUserList.push(data.id);
        }
      } else {
        this.selectedUserList.push(data.id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  submitStatusForm(action) {
    this.submitted = true;
    if (this.selectedUserList.length) {
      /* DELETE */
      if (action === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete?').then((result) => {
          if (result.value) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.type !== 'userList' ? this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete') : this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'post');
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      } else if (action === 'Restore') {
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to restore?').then((result) => {
          if (result.value) {
            const data = { [this.params.actionParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.RestoreCourse, data, 'post');
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      } else if (this.type !== 'courseRequests' && (action === 'Inactive' || action === 'Active')) {
        let data = action === 'Inactive' ? { [this.params.statusParams]: this.selectedUserList, status: 'Inactive' } : { [this.params.statusParams]: this.selectedUserList, status: 'Active' };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      } else if (this.type === 'courseRequests') {
        let data = { [this.params.statusParams]: this.selectedUserList, status: action === 'In Progress' ? 'Inprogress' : action };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      }
    } else {
      this.commonService.showSkeletonLoader = false;
      this.showSkeletonLoader1 = false;
      this.showErrorService.popToast('error', 'Select Users To Modify');
    }
  }

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [5, 10, 25, 50, 100, 200];
  itemsPerPage = 100;

  pagination(e) {
    let count = 0;
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.callApiToGetData();
    this.tableConfig.cols.forEach(element => {
      element.checkbox = false;
    });
    setTimeout(() => {
      if (this.selectedUserList.length) {
        this.selectedUserList.forEach((element) => {
          const x = this.tableData.data.findIndex((o) => element === o.id);
          if (x > -1) {
            this.tableData.data[x].checkbox = true;
            if (this.tableData.data[x].checkbox) {
              count++;
            }
            if (count === this.tableData.data.length) {
              this.tableConfig.cols[0].checkbox = true;
            }
          }
        });
      }
    }, 500);
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.per_page = e;
    this.APIparameters.page = 1;
    this.callApiToGetData();
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
    this.tableData.data.forEach(ele => ele.isOpen = false);
    this.openFilterButton = false;
  }

  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) { count++; }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex((o) => o.isVisible && o.columnVisibility);
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data, methodName) {
    this.commonService.callApi(callAPI, data, methodName, false, false, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast('success', success.extra_meta.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
        this.selectedUserList = [];
      } else {
        this.commonService.showSkeletonLoader = false;
        this.showSkeletonLoader1 = false;
        this.showErrorService.popToast('error', success.errors.message);
      }
    });
  }


  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        /* Destroy the table first */
        dtInstance.destroy();
        /* Call the dtTrigger to rerender again */
        this.dtTrigger.next();
      });
    } else { this.dtTrigger.next(); }
  }


  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    if (this.type === 'emailTemplateList') {
      event.sortDirection = event.sortDirection === 'desc' ? -1 : 1;
      let data = {
        sort_by: { [event.sortColumn.colFieldname]: event.sortDirection }
      }
      this.APIparameters.sort_by = JSON.stringify(data.sort_by);
    } else {
      this.APIparameters.sort_by = event.sortColumn.colFieldname;
      this.APIparameters.order_by = event.sortDirection;
    }
    this.callApiToGetData();
  }

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/
  performAction(k, i) {
    switch (k.id) {
      /* VIEW action */
      case 1:
        let detailPageUrl = '';
        switch (this.type) {
          case 'userList':
            detailPageUrl = this.URLConstants.USER_DETAIL;
            break;
          case 'newsList':
            detailPageUrl = this.URLConstants.VIEW_NEWS;
            break;
          case 'operatorList':
          case 'userOperatorlist':
            detailPageUrl = this.URLConstants.OPERATOR_DETAIL;
            break;
          case 'contentProviderList':
            detailPageUrl = this.URLConstants.CONTENT_PROVIDERS_DETAIL;
            break;

          default:
            break;
        }
        this.router.navigate([detailPageUrl, i.id]);
        break;
      /* EDIT action */
      case 2:
        let editPageUrl = '';
        switch (this.type) {
          case 'emailTemplateList':
            editPageUrl = this.URLConstants.EDIT_EMAIL_TEMPLATE;
            break;
          case 'roleList':
            editPageUrl = this.URLConstants.EDIT_ROLE;
            break;
          case 'teamMemeberList':
            editPageUrl = this.URLConstants.EDIT_TEAM_MEMBER;
            break;
          case 'operatorList':
            editPageUrl = this.URLConstants.EDIT_OPERATOR;
            break;
          case 'contentProviderList':
            editPageUrl = this.URLConstants.EDIT_CONTENT_PROVIDERS;
            break;
          case "pushNotificationTemplateList":
            editPageUrl = this.URLConstants.EDIT_PUSH_NOTIFICATION_TEMPLATE;
            break;
          case 'industryList':
            editPageUrl = this.URLConstants.EDIT_INDUSTRY;
            break;
          case 'productTypesList':
            editPageUrl = this.URLConstants.EDIT_PRODUCT_TYPE;
            break;
          case 'cancellationReasonList':
            editPageUrl = this.URLConstants.EDIT_CANCELLATION_REASON;
            break;
          default:
            break;
        }
        this.router.navigate([editPageUrl, i.id]);

        break;
      /* DELETE action */
      case 3:
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete?').then((result) => {
          if (result.value) {
            const dataObj = {
              [this.params.deleteParams]: [i.id],
            };
            let methodType = 'post';
            if (this.type === 'teamMemeberList' || this.type === 'operatorList' || this.type === 'industryList' || this.type === 'productTypesList' || this.type === 'cancellationReasonList') {
              this.DeleteUser = this.DeleteUser + i.id;
              methodType = 'delete';
            }
            this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj, methodType);
          }
        });
        break;
      /* RESEND PASSWORD action */
      case 4:
        this.commonService.callApi(this.ResendPassword + i.id, '', 'get', false, false).then((success) => {
          if (!success.errors) {
            this.showErrorService.popToast('success', success.extra_meta.message);
          } else {
            this.commonService.showSkeletonLoader = false;
            this.showSkeletonLoader1 = false;
            this.showErrorService.popToast('error', success.errors.message);
          }
        });
        break;
      /* OTHERS */
      case 5:
        this.router.navigate([this.URLConstants.OPERATORS_DOMAIN, i.id]);
        break;
      case 6:
        this.router.navigate([this.URLConstants.OPERATORS_FEATURE_SETTINGS, i.id]);
        break;
      case 7:
        this.router.navigate([this.URLConstants.OPERATORS_DELETED_COURSES, i.id]);
        break;
      case 8:
        this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to restore?').then((result) => {
          if (result.value) {
            const data = {
              [this.params.actionParams]: [i.id],
            };
            this.CallAPIchangeStatusORDelete(this.RestoreCourse, data, 'post');
          }
        });
        break;
      default:
        break;
    }
  }
  /* SWITCH action */
  changeStatus(d) {
    const dataObj = {
      [this.params.statusParams]: [d.id],
      status: d.status ? 'Inactive' : 'Active'
    };
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj, 'post');
  }

  /***************************************************************
    @Purpose :  Drag & Drop columns
  /***************************************************************/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }

  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element.id);
          if (x === -1) {
            this.selectedUserList.push(element.id);
          }
        } else {
          this.selectedUserList.push(element.id);
        }
        if (this.type === 'operatorList' && element.id === 1) {
          let index = this.selectedUserList.findIndex((o) => o === element.id);
          this.selectedUserList.splice(index, 1);
        }
      });

    } else {
      this.deselectMultipleUser();
    }
  }

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }


  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterList: any[] = [];
  public filters = {};
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
    this.status = true;
  }

  getSatutsFilterlistData(colName) {
    if (colName === 'status' && this.type !== 'courseRequests') {
      this.statusFilterList = ['Active', 'Inactive'];
    } else {
      this.statusFilterList = [{ title: 'PENDING', value: 'Pending' }, { title: 'IN PROGRESS', value: 'Inprogress' }, { title: 'COMPLETED', value: 'Completed' }];
    }
  }

  changeStatusFromList(e, obj) {
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, { ids: [obj.id], status: e.value }, 'post');
  }
  applyFilter() {
    this.filters = {};
    this.APIparameters.page = 1;
    this.tableConfig.cols.forEach(e => {
      if (e.filter && e.value) {
        if (e.isDate || e.isDateTime) {
          this.filters[e.colFieldname] = new DatePipe('en-US').transform(e.value, 'yyyy-MM-dd');
        } else {
          this.filters[e.colFieldname] = e.value;
        }
      }
    });
    if (Object.keys(this.filters).length !== 0) {
      this.disableResetFilter = false;
      this.APIparameters.filters = this.filters;
      this.callApiToGetData();
    } else {
      this.commonService.showSkeletonLoader = false;
      this.showSkeletonLoader1 = false;
      this.showErrorService.popToast('error', 'Please fill at least one field to filter')
    }
  }

  resetFilter() {
    this.disableResetFilter = true;
    this.filters = {};
    this.tableConfig.cols.forEach((element) => {
      element.value = '';
    });
    this.APIparameters.filters = {};
    this.callApiToGetData();
  }
  verifyEmail(i) {
    this.commonService.callApi(this.callAPIConstants.verifyRegisteredUser, { user_id: i.id }, 'post', false, false, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast('success', success.extra_meta.message);
        this.callApiToGetData();
      } else {
        this.showErrorService.popToast('error', success.errors.message);
      }
    });
  }

  /****************************************************************************
  /* To get search list */
  /****************************************************************************/
  searchData(event) {
    this.APIparameters.page = 1;
    if (event.target && event.target.value) {
      this.APIparameters.search_key = event.target.value;
    } else {
      delete this.APIparameters.search_key;
    }
    this.callApiToGetData();
  }

  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/
  searchValue(data) {
    this.APIparameters.search_key = data;
    this.callApiToGetData();
  }

  /* For performing selected action on the list */
  actionSelected(action) {
    /* DELETE */
    if (action === 'Delete') {
      this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to delete?').then((result) => {
        if (result.value) {
          const data = {
            userIds: this.selectedUserList
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
        } else {
          this.deselectMultipleUser();
        }
      });
    }
    /* STATUS */
    if (action === 'Inactive' || action === 'Active') {
      let data = action === 'Inactive' ? { userIds: this.selectedUserList, status: false } : { userIds: this.selectedUserList, status: true };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
      this.deselectMultipleUser();
    }
  }

  /* To clear the filter */
  clearFilter() {
    this.filteredArr = [];
    this.addTask();
    this.callApiToGetData();
    this.filterForm.reset();
    this.submitted = false;
    this.submitted1 = false;
  }

  typeArrChanges(item) {
    delete (item.selectedDate);
    delete (item.value);
  }

  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.search_key;
    this.callApiToGetData();
  }

  public isOpen = false;
  public openFilterButton = false;
  dropdownToggling(index) {
    this.tableData.data[index].isOpen = true;
    let filteredArr = [];
    this.tableData.data.forEach((ele, i) => {
      filteredArr.push(i);
    });
    let updatedArr = filteredArr.filter(ele => ele !== index);
    updatedArr.forEach(ele => {
      this.tableData.data[ele].isOpen = false;
    })
    this.columnVisibilityOptions = false;
    this.openFilterButton = false;
    this.status = false;
  }
  /***************************************************************/
}

<!-- BEGIN: Sub Header  -->
<app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>
<!-- END: Sub Header  -->
<div class="main-content-area">
    <div class="page-title">
        <h1>{{title}}</h1>
        <div class="page-button">
            <button class="btn btn-outline btn-primary" (click)="responsiveDataTable.exportTemplate()">Export</button>
        </div>
    </div>
    <div class="m-content animated fadeIn">
        <!-- BEGIN: Responsive Data Table  -->
        <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
        </app-responsive-data-table>
        <!-- END: Responsive Data Table  -->
    </div>
</div>